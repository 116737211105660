import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CouponTypes } from '@enums/coupon-types';
import { DataLayerCategories } from '@enums/data-layer-categories';
import { PaymentTypes } from '@enums/payment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { InHomeBookingResult, InHomeCollectionService, ResultService } from '@Medology/ng-findalab';
import { Address } from '@models/address';
import { Coupon } from '@models/coupon';
import { ExtraCharge } from '@models/extra-charge';
import { GiftCardPaymentRequest } from '@models/gift-card-payment-request';
import { HasBetterLabShowResponse } from '@models/has-better-lab-show-response';
import { LegacyUpsellStoreResponse } from '@models/legacy-upsell-store-response.model';
import { LoadCartOptions } from '@models/load-cart-options';
import { LoadCartRequest } from '@models/load-cart-request';
import { LoadCartResponse } from '@models/load-cart-response';
import { OrderResponse } from '@models/order-response';
import { OrderUpgradeResponse } from '@models/order-upgrade-response';
import { OrderUpsell } from '@models/order-upsell';
import { PlaceOrderRequest } from '@models/place-order-request';
import { RechargeRequest } from '@models/recharge-request';
import { Reorder } from '@models/reorder';
import { ReorderResponse } from '@models/reorder-response';
import { AbstractPaymentStoreRequest } from '@models/requests/abstract-payment-store-request.model';
import { AddressUpdateRequest } from '@models/requests/address-update.model';
import { LegacyUpsellStoreRequest } from '@models/requests/legacy-upsell-store.model';
import { PaymentBraintreeStoreRequest } from '@models/requests/payment-braintree-store-request.model';
import { PaymentCreditCardStoreRequest } from '@models/requests/payment-credit-card-store-request.model';
import { PaymentPayLaterStoreRequest } from '@models/requests/payment-paylater-store-request.model';
import { ShippingAddressStoreRequest } from '@models/requests/shipping-address-store.model';
import { UpsellStoreRequest } from '@models/requests/upsell-store.model';
import { ShippingAddressStoreResponse } from '@models/responses/shipping-address-store-response.model';
import { STDcheckLoadCartRequest } from '@models/stdcheck-load-cart-request';
import { STDcheckLoadCartResponse } from '@models/stdcheck-load-cart-response';
import { TransactionResponse } from '@models/transaction-response';
import { UpgradeResponse } from '@models/upgrade-response';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { firstValueFrom, forkJoin, mergeMap, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { DomainService } from './domain.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OrderService {
  reorderData: Reorder = null;
  rechargeData: RechargeRequest = null;
  giftCardApplied = 0;
  giftCardAppliedSubject$ = new Subject<number>();
  labResultSubscription: Subscription;
  placeOrderButtonDisable: boolean = false;

  protected apiUrl = this.config.analyteCareApi;
  protected checkoutForm: FormGroup;
  protected partnerDiscount: number = 1000;
  protected orderPlacedRedirectPage: string = '/order-address.php';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected router: Router,
    protected storage: StorageService,
    protected results: ResultService,
    protected formService: FormService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected inHomeService: InHomeCollectionService,
    protected dataLayerService: DataLayerService,
    protected sessionStorageService: SessionStorageService
  ) {
    this.checkoutForm = this.formService.checkout;
    // Update the order when lab is selected/changed
    this.labResultSubscription = this.listenForLabSelection();
  }

  /**
   * Determine if a second payment is necessary by checking if the gift card applied (if there is one)
   * is enough to pay for the entire order.
   */
  get isSecondaryPaymentNeeded(): boolean {
    return this.giftCardApplied && this.giftCardApplied < this.getTotal();
  }

  /**
   * Gets the order extra charges.
   */
  get extraCharges(): ExtraCharge[] {
    return this.storage.extraCharges;
  }

  /**
   * Gets the order upsells.
   */
  get orderUpsells(): OrderUpsell[] {
    return this.storage.orderUpsells;
  }

  /**
   * Gets the amount to charge by subtracting the gift card applied from the order total and converting to dollars from
   * cents.
   */
  get invoiceAmountInUsd(): number {
    return (this.getTotal() - this.giftCardApplied) / 100;
  }

  /**
   * Load cart request.
   *
   * We are able to retrieve the current cart
   *
   * @param {LoadCartRequest} request Load Cart Request containing the parameters to load the cart
   *
   * @returns {Observable<LoadCartResponse>} an Observable of the LoadCartResponse.
   */
  loadCartRequest(request: LoadCartRequest): Observable<STDcheckLoadCartResponse> {
    return this.http
      .get<LoadCartResponse>(`${this.config.apiUrl}/load-cart`, {
        params: request.getHttpParams(),
        headers: request.getHttpHeaders(),
      })
      .pipe((request) => this.handleLoadCartResponse(request));
  }

  /**
   * Load cart
   *
   * @param {LoadCartOptions} options The load cart options
   */
  abstract loadCart(options?: LoadCartOptions): Observable<STDcheckLoadCartResponse>;

  /**
   * Get a Subscription to the lab selection that chooses the tests in each event.
   */
  abstract listenForLabSelection(): Subscription;

  /**
   * Calculates the discount to apply to the order.
   *
   */
  protected abstract getTotalDiscount(): number;

  /**
   * Gets whether the user applied partner testing or not.
   */
  hasPartner(): boolean {
    return this.storage.hasPartner;
  }

  /**
   * Parses the load cart response to an STDcheckLoadCartResponse model. This is the default model that we should use.
   *
   * @param {LoadCartResponse} response the API response to parse
   */
  abstract parseCartResponse(response: LoadCartResponse): Observable<STDcheckLoadCartResponse>;

  /**
   * Gets the discount amount to apply based on coupons for the specified amount.
   *
   * @param {number} amount the amount to calculate the coupon discount for
   *
   * @returns {number} the total coupon discount calculated
   */
  getTotalCoupons(amount: number): number {
    if (!this.storage.coupon) {
      return 0;
    }

    if (this.storage.coupon.coupon_type == CouponTypes.Percentage) {
      return (Number(this.storage.coupon.coupon_amount) / 100) * amount;
    }

    return Number(this.storage.coupon.coupon_amount) * 100;
  }

  /**
   * Handle the response of the load cart request.
   *
   * @param {Observable<LoadCartResponse>} observable The observable to handle the response of
   *
   * @returns {Observable<LoadCartResponse>} The observable with the response handled
   */
  handleLoadCartResponse(observable: Observable<LoadCartResponse>): Observable<STDcheckLoadCartResponse> {
    return observable.pipe(
      map((response) => this.validateCartResponse(response)),
      switchMap((response) => this.parseCartResponse(response)),
      tap((response) => this.saveCartResponse(response)),
      catchError((error: HttpErrorResponse) => this.handleLoadCartError(error))
    );
  }

  /**
   * Validate the response of the load cart request, current validations:
   * - The tests array is not empty
   *
   * @param {LoadCartResponse} response the api response that contains the data to validate
   *
   * @returns {LoadCartResponse} the response validated
   */
  validateCartResponse(response: LoadCartResponse): LoadCartResponse {
    if (!response.tests?.length) {
      throw new Error('The tests array is empty.');
    }

    return response;
  }

  /**
   * Handles errors from the load cart request or response validation.
   *
   * If an error occurs, it will be logged to the console, and the user will be redirected to the order error page.
   *
   * @param {any} error the error to handle
   *
   * @returns {Observable<never>} an empty observable
   */
  handleLoadCartError(error: HttpErrorResponse): Observable<never> {
    console.error(error);
    this.navigateToOrderErrorPage();

    return of();
  }

  /**
   * Adds the in-home collection booking data to the order request.
   *
   * @param {PlaceOrderRequest} placeOrderRequest the order request to add the in-home collection booking data to
   */
  protected addInHomeCollectionBookingToOrderRequest(placeOrderRequest: PlaceOrderRequest): void {
    const inHomeBookingResult: InHomeBookingResult = this.storage.inHomeBookingResult;
    placeOrderRequest.in_home_collection = {
      appointment_key: inHomeBookingResult.bookingSlot.key,
      street: inHomeBookingResult.address.street,
      city: inHomeBookingResult.address.city,
      state: inHomeBookingResult.address.state,
      zip_code: inHomeBookingResult.address.zipCode,
      provider_id: inHomeBookingResult.bookingSlot.providerId,
    };
    placeOrderRequest.state = inHomeBookingResult.address.state;
  }

  /**
   * Clear the context of the last order in the application.
   */
  clearLastOrder(): void {
    this.storage.fee = null;
    this.storage.transactionId = null;
    this.storage.addressComplete = null;
    this.storage.coupon = null;
    this.storage.free = null;
    this.storage.paymentType = null;
    this.storage.labChanged = null;
    this.storage.orderType = null;
    this.storage.patient = null;
    this.storage.tests = null;
    this.storage.orderTotal = null;
    this.storage.hasEmail = null;
    this.storage.email = null;
    this.storage.sendSMS = null;
    this.storage.upsell = null;
    this.storage.secondaryPaymentTransactionId = null;
    this.storage.inHomeBookingResult = null;
    this.storage.order = null;
    this.storage.treatmentType = null;
    this.storage.extraCharges = null;
    this.storage.orderUpsells = null;
  }

  /**
   * Calculates the total amount of the order, taking into account the subtotal,
   * any applicable fees, and discounts.
   *
   * @returns the total amount of the order
   */
  getTotal(): number {
    const testsTotal = this.getTestsTotal();
    const partnerTotal = this.getPartnerTotal();

    return Math.max(
      0,
      testsTotal +
        partnerTotal +
        this.getExtraChargesTotal() +
        this.getOrderUpsellsTotal() +
        this.getInHomeCollectionTotal() +
        this.getTotalComplianceFee() -
        this.getTotalDiscount() -
        this.getTotalCoupons(testsTotal + partnerTotal)
    );
  }

  /**
   * Get available upgrade.
   */
  getUpgrade(request: STDcheckLoadCartRequest): Observable<UpgradeResponse> {
    return this.http.post<UpgradeResponse>(`${this.apiUrl}/order-upgradable-tests`, request);
  }

  /**
   * Submits the order.
   *
   * @param {string} deviceData the device data from Braintree
   */
  submitOrder(deviceData: string): Promise<OrderResponse> {
    const request = new PlaceOrderRequest(this.getOrderRequest(deviceData));
    request.site_name = this.domainService.getSiteDomain();

    return firstValueFrom(
      this.http.post<OrderResponse>(`${this.apiUrl}/api/v1/order`, request, this.authService.getOptionsWithAuthHeader())
    );
  }

  /**
   * Make an additional payment to an order with the gift card applied.
   */
  makeSecondaryPaymentWithGiftCard(orderId: string): Promise<TransactionResponse> {
    const request = new GiftCardPaymentRequest(
      this.checkoutForm.get('payment').get('giftCardCode').value,
      this.giftCardApplied,
      orderId
    );

    return firstValueFrom(
      this.http.post<TransactionResponse>(
        `${this.apiUrl}/api/v1/order/${request.order_id}/payment`,
        request,
        this.authService.getOptionsWithAuthHeader()
      )
    );
  }

  /**
   * Gets the details of the order to reorder.
   *
   * @param {string} reorderId the transaction ID of the order to reorder
   * @param {string} token the account token cookie value
   *
   * @returns {Observable<ReorderResponse>} an observable that emits a ReorderResponse object
   */
  getReorder(reorderId: string, token: string): Observable<ReorderResponse> {
    return this.http.get<ReorderResponse>(
      `${this.apiUrl}/my-account/reorder/${reorderId}`,
      this.authService.getOptionsWithAuthHeader(token)
    );
  }

  /**
   * Stores an order address.
   *
   * @param addressUpdateRequest data from order address form
   */
  storeAddress(addressUpdateRequest: AddressUpdateRequest): Observable<any> {
    const body = {
      ...addressUpdateRequest,
      phone: {
        ...addressUpdateRequest.phone,
        sendSms: addressUpdateRequest.phone.sendSms.toString(),
        leaveMsg: addressUpdateRequest.phone.leaveMsg.toString(),
      },
      agreeTerms: addressUpdateRequest.agreeTerms.toString(),
    };

    return this.http.post(`${this.apiUrl}/api/v1/order/${this.transactionId}/address`, body);
  }

  /**
   * Updates an order address.
   *
   * @param {Address} address data from order address form
   * @param {string} transactionId the transaction ID of the order
   * @param {string} hash the hash of the order
   */
  updateAddress(address: Address, transactionId: string, hash: string): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/v1/order/${transactionId}/address`, address, {
      headers: new HttpHeaders({ 'X-API-Hash': hash }),
    });
  }

  /**
   * Store the payment information, the payment will be charged in the API.
   *
   * @param {PaymentStoreRequest} requestBody the payment request body
   * @param {string} hash the hash of the order
   */
  storePayment(requestBody: AbstractPaymentStoreRequest, hash: string): Observable<TransactionResponse> {
    return this.http.post<TransactionResponse>(
      `${this.apiUrl}/api/v1/order/${requestBody.order_id}/payment`,
      requestBody,
      {
        headers: new HttpHeaders({ 'X-API-Hash': hash }),
      }
    );
  }

  /**
   * Generate the payment request based on the payment method selected.
   * The payment request will be used to store the payment in the API.
   *
   * @param {string} orderId           the order ID
   * @param {any}    paymentFormValues the payment form values
   * @param {number} chargeAmount      the charge amount
   * @param {string} holderName        the card holder name
   */
  getStorePaymentRequest(
    orderId: string,
    paymentFormValues: any,
    chargeAmount: number,
    holderName: string
  ): AbstractPaymentStoreRequest {
    switch (paymentFormValues.method) {
      case PaymentTypes.CreditCard:
        return new PaymentCreditCardStoreRequest(orderId, chargeAmount, paymentFormValues.creditCard, holderName);
      case PaymentTypes.ApplePay:
      case PaymentTypes.GooglePay:
      case PaymentTypes.Venmo:
      case PaymentTypes.Paypal:
        return new PaymentBraintreeStoreRequest(orderId, chargeAmount, paymentFormValues.nonce);
      case PaymentTypes.PayLater:
        return new PaymentPayLaterStoreRequest(orderId);
    }
  }

  /**
   * Store the payment information, the payment will be charged in the API.
   * This method is used to store the additional payment.
   *
   * @param {OrderUpsell} orderUpsell   the order upsell
   * @param {string}      transactionId the transaction ID of the order
   * @param {string}      hash          the hash of the order
   */
  storeUpsell(orderUpsell: OrderUpsell, transactionId: string, hash: string): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/api/v1/order/${transactionId}/upsells`,
      new UpsellStoreRequest(orderUpsell),
      { headers: new HttpHeaders({ 'X-API-Hash': hash }) }
    );
  }

  /**
   * Get a list of tests available for upsell.
   *
   * @param {number[]} testIds the ids of the tests to get upsells for
   */
  getOrderUpsells(testIds: number[]): Observable<OrderUpsell[]> {
    return this.http
      .get<OrderUpsell[]>(`${this.apiUrl}/api/v1/upsells`, {
        params: this.getUpsellParams(testIds),
      })
      .pipe(
        map((response: OrderUpsell | OrderUpsell[]) =>
          response === null ? [] : Array.isArray(response) ? response : [response]
        )
      );
  }

  /**
   * Get upsell params.
   *
   * @param {number[]} testIds the ids of the tests to get upsells for
   */
  private getUpsellParams(testIds: number[]): HttpParams {
    let params = new HttpParams();
    testIds.forEach((id) => (params = params.append('tests[]', id.toString())));
    params = params.append('visibility', 'web');

    if (this.domainService.isHealthlabsDomain()) {
      params = params.append('useDefault', '1');
    }

    return params;
  }

  /**
   * Update Current Order lab
   *
   * @param {number} centerId the id of the center to update the order to
   */
  updateLab(centerId: number): Observable<{ success: Boolean }> {
    return this.http.put<{ success: Boolean }>(`${this.apiUrl}/api/v1/order/${this.transactionId}/lab`, {
      center_id: centerId,
    });
  }

  /**
   * Applies the current gift card to the cart.
   *
   * @param balance gift card balance
   */
  applyGiftCard(balance: number): void {
    const total = this.getTotal();
    this.giftCardApplied = balance ? (total < balance * 100 ? total : balance * 100) : 0;
    this.giftCardAppliedSubject$.next(this.giftCardApplied);
  }

  /**
   * Gets a gift card's balance by the code provided.
   *
   * @param {string} code the gift card code
   */
  checkGiftCardBalance(code: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/v1/gift-card-balance/${code}`);
  }

  /**
   * Gets the payment recharge information
   *
   * @param {string} token The JWT token
   */
  getPaymentRechargeable(token: string): Observable<RechargeRequest> {
    return this.http.get<any>(`${this.apiUrl}/payment-rechargeable`, this.authService.getOptionsWithAuthHeader(token));
  }

  /**
   * Update storage from response.
   */
  updateStorage(response: STDcheckLoadCartResponse | OrderUpgradeResponse): void {
    this.storage.tests = response.tests;
    this.storage.fee = response.fee;
    this.storage.coupon = response.coupon;

    if (response.center) {
      this.storage.center = response.center;
      this.formService.checkout.get('lab_id').setValue(this.storage.center.id);
    }
  }

  /**
   * Places an upsell order using the legacy API.
   *
   * @param { LegacyUpsellStoreRequest } upsellStoreRequest data containing upsells to store
   * @param { string }                   transactionId      the transaction ID of the order
   */
  legacyStoreUpsell(
    upsellStoreRequest: LegacyUpsellStoreRequest,
    transactionId: string = this.transactionId
  ): Observable<LegacyUpsellStoreResponse> {
    if (Array.isArray(upsellStoreRequest.upsell) && upsellStoreRequest.upsell.length === 0) {
      return of(null);
    }

    return this.http.post<LegacyUpsellStoreResponse>(
      `${this.apiUrl}/api/v1/order/${transactionId}/upsell`,
      upsellStoreRequest
    );
  }

  /**
   * Submits the order with the upsells in multiple requests.
   *
   * @param {OrderUpsell[]}               upsells        the upsells to submit
   * @param {AbstractPaymentStoreRequest} paymentRequest the payment request
   * @param {string}                      hash           the hash of the order
   */
  submitUpsells(
    upsells: OrderUpsell[],
    paymentRequest: AbstractPaymentStoreRequest,
    hash: string
  ): Observable<TransactionResponse> {
    return this.storePayment(paymentRequest, hash).pipe(
      mergeMap((transactionResponse: TransactionResponse) => {
        const upsellRequests = upsells.map((upsell) =>
          this.storeUpsell(upsell, paymentRequest.order_id, hash).pipe(
            switchMap((testId: number) =>
              this.storeShippingAddressForTests(upsell.name, [testId], paymentRequest.order_id, hash).pipe(
                catchError((error: any) => this.swallowError(error))
              )
            ),
            catchError((error: any) => this.swallowError(error))
          )
        );

        return forkJoin(upsellRequests).pipe(map(() => transactionResponse));
      })
    );
  }

  /**
   * Store the shipping address for a test.
   *
   * @param {string}   upsellName    the name of the upsell
   * @param {number[]} testIds       the test ID generated for the stored upsell
   * @param {string}   transactionId the transaction ID of the order
   * @param {string}   hash          the hash of the order
   */
  storeShippingAddressForTests(
    upsellName: string,
    testIds: number[],
    transactionId: string,
    hash: string
  ): Observable<ShippingAddressStoreResponse> {
    let address: any;
    switch (upsellName) {
      case UpsellSlugs.MensIntimateWash:
        address = this.sessionStorageService.address;
        break;
      case UpsellSlugs.DeliverMedication:
        address = this.sessionStorageService.shippingAddress;
        break;
      default:
        return of(null);
    }

    return this.storeShippingAddress(
      new ShippingAddressStoreRequest(address, this.sessionStorageService.patient, testIds),
      transactionId,
      hash
    );
  }

  /**
   * Swallows an error and logs it to the console.
   *
   * @param {any} error the error to swallow
   */
  swallowError(error: any): Observable<any> {
    console.error(error);

    return of(null);
  }

  /**
   * Get the order has_better_lab flag.
   */
  showOrderBetterLab(): Observable<HasBetterLabShowResponse> {
    return this.http.get<HasBetterLabShowResponse>(`${this.apiUrl}/api/v1/order/${this.transactionId}/has_better_lab`);
  }

  /**
   * Mark the order as completed so requisition is created by the API.
   */
  orderCompleted(): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/v1/order/${this.transactionId}/completed`, {});
  }

  /**
   * Makes a request to the API to add a test to an order.
   *
   * @param {number} testId the ID of the test to add
   * @param {string} transactionId the transaction ID of the order
   * @param {string} hash the hash of the order
   *
   * @returns {Observable<string>} an observable that emits the transaction ID of the order
   */
  addTestToOrder(testId: number, transactionId: string, hash: string): Observable<string> {
    return this.http.post<string>(
      `${this.apiUrl}/api/v1/order/${transactionId}/tests`,
      { test_id: testId },
      {
        headers: new HttpHeaders({ 'X-API-Hash': hash }),
      }
    );
  }

  /**
   * Updates the partner email in the order.
   *
   * @param { string } partnerEmail the partner email to store
   * @param { string } transactionId the transaction ID of the order
   * @param { string } hash the hash of the order
   */
  storePartnerEmail(partnerEmail: string, transactionId: string, hash: string): Observable<void> {
    return this.http.put<void>(
      `${this.apiUrl}/api/v1/order/${transactionId}`,
      { partner_email: partnerEmail },
      {
        headers: new HttpHeaders({ 'X-API-Hash': hash }),
      }
    );
  }

  /**
   * Creates the order request body from the form and storage variables.
   *
   * @param {string} deviceData the device data from Braintree
   */
  protected getOrderRequest(deviceData: string): PlaceOrderRequest {
    const checkout = this.checkoutForm.value;
    const dob = new Date(
      checkout.patient.birthday.year,
      checkout.patient.birthday.month - 1,
      checkout.patient.birthday.day
    );

    const placeOrderRequest = new PlaceOrderRequest({
      first_name: checkout.patient.firstName,
      last_name: checkout.patient.lastName,
      gender: checkout.patient.sex,
      dob: dob.toISOString().slice(0, 10).replace('T', ' '),
      lab_id: this.storage.center?.id,
      accountType: !this.config.forceEmailResults ? checkout.patient.contactVia : 'email',
      cjevent: this.storage.getCookie('cje') || this.storage.getCookie('cjevent_dc'),
    });

    if (this.extraCharges.length) {
      placeOrderRequest.extra_charge = {
        name: `Extra Charge - ${this.extraCharges.map((charge) => charge.name).join(', ')}`,
        amount: this.getExtraChargesTotal(),
      };
    }

    if (deviceData) {
      placeOrderRequest.deviceData = deviceData;
    }

    if (!this.config.forceEmailResults) {
      placeOrderRequest.leave_message = this.checkoutForm.value.patient.voicemail_allowed ? 'YES' : 'NO';
    }

    if (checkout.patient.phone) {
      placeOrderRequest.phone = checkout.patient.phone;
    }

    if (checkout.patient.email) {
      placeOrderRequest.email = checkout.patient.email;
    }

    if (this.hasPartner() && checkout.patient.partnerEmail && !this.storage.free) {
      placeOrderRequest.partner_email = checkout.patient.partnerEmail;
    }

    if (this.storage.orderUpsells?.length) {
      placeOrderRequest.upsells = this.storage.orderUpsells.map((upsell) => upsell.id);
    }

    placeOrderRequest.parsePaymentMethod(this.isSecondaryPaymentNeeded ? checkout.secondaryPayment : checkout.payment);
    placeOrderRequest.setChargeAmount(this.isSecondaryPaymentNeeded ? this.getTotal() - this.giftCardApplied : null);

    this.storage.patient = {
      name: placeOrderRequest.first_name + ' ' + placeOrderRequest.last_name,
      email: placeOrderRequest.email ?? '',
      phone: placeOrderRequest.phone ?? '',
    };
    this.storage.hasEmail = !!placeOrderRequest.email;
    this.storage.paymentType = placeOrderRequest.payment_type;

    return placeOrderRequest;
  }

  /**
   * Saves the cart response in the storage.
   *
   * @param {STDcheckLoadCartResponse} response the api response that contains the data to be saved
   */
  protected saveCartResponse(response: STDcheckLoadCartResponse): void {
    this.updateStorage(response);
  }

  /**
   * Navigates to the error page if something went wrong while reordering.
   */
  protected navigateToOrderErrorPage(): void {
    this.document.location.href = this.config.siteUrls.tests;
  }

  /**
   * Makes a request to the API to get a coupon by its code.
   */
  protected getCoupon(code: string): Observable<Coupon> {
    return this.http.get<Coupon>(`${this.apiUrl}/coupon/${code}`);
  }

  /**
   * Replaces the response coupon with the coupon from the API.
   *
   * @param {STDcheckLoadCartResponse} response the load cart response
   *
   * @returns {Observable<STDcheckLoadCartResponse>} an observable of the load cart response with the coupon replaced
   */
  protected parseCoupon(response: STDcheckLoadCartResponse): Observable<STDcheckLoadCartResponse> {
    if (!response.coupon) {
      return of(response);
    }

    return this.getCoupon(response.coupon?.coupon_code).pipe(
      map((couponResponse) => ({ ...response, coupon: couponResponse })),
      catchError(() => of(response))
    );
  }

  /**
   * Calculates the total of the order tests
   *
   * @returns {number} The total of the order tests
   */
  getTestsTotal(): number {
    return this.storage.tests.reduce((sum, current) => sum + current.price, 0);
  }

  /**
   * Calculates the partner total on the order
   *
   * @returns {number} The partner total
   */
  getPartnerTotal(): number {
    return this.hasPartner() ? this.getTestsTotal() : 0;
  }

  /**
   * Calculates the extra charges total on the order.
   *
   * @returns {number} the total of the extra charges
   */
  getExtraChargesTotal(): number {
    return this.extraCharges.reduce((sum, current) => sum + current.amount, 0);
  }

  /**
   * Calculates the total of the order upsells.
   *
   * @returns {number} the total of the order upsells
   */
  getOrderUpsellsTotal(): number {
    return this.orderUpsells.reduce((sum, current) => sum + current.upsell_price, 0);
  }

  /**
   * Calculates the in home collection total on the order
   *
   * @returns {number} The in home collection total
   */
  getInHomeCollectionTotal(): number {
    return this.storage.inHomeBookingResult ? this.config.inHomeCollectionCostInCents : 0;
  }

  /**
   * Calculates the compliance fee total
   *
   * @returns {number} The compliance fee total
   */
  getTotalComplianceFee(): number {
    return this.storage.fee?.amount ?? 0;
  }

  /**
   * Get the tests from the url.
   */
  get testsFromUrl(): string | string[] {
    const url = new URL(document.location.href);
    const panelArr = url.searchParams.getAll('panel[]');
    const testsArr = url.searchParams.getAll('tests[]');

    return (
      url.searchParams.get('panel') ||
      url.searchParams.get('tests') ||
      (testsArr.length ? testsArr : null) ||
      (panelArr.length ? panelArr : null)
    );
  }

  /**
   * Getter for transaction_id
   */
  get transactionId(): string {
    return this.storage.transactionId;
  }

  /**
   * Gets the coupon code from the url or the storage, or returns null if there is no coupon.
   */
  get couponCode(): string | null {
    const url = new URL(document.location.href);

    return url.searchParams.get('coupon') || this.storage.coupon?.coupon_code || null;
  }

  /**
   * Stores an upsell test in the storage array if it doesn't already exist
   *
   * @param {OrderUpsell} upsellTest The upsell test object
   */
  addUpsellTestToStorage(upsellTest: OrderUpsell): void {
    let tests = this.storage.tests;
    const { id, name, price, slug } = upsellTest;

    if (tests.some((test) => test.name == name)) {
      return;
    }

    tests.push({ id, name, price, slug });
    this.storage.tests = tests;
  }

  /**
   * Adds the upsell price to the order total
   *
   * @param {number} price The price to add to the order total
   */
  addUpsellPriceToTotal(price: number): void {
    this.storage.orderTotal += price;
  }

  /**
   * Handle the redirection after order is placed
   *
   * @param {OrderResponse} response the Order Response
   */
  afterOrderPlacement(response: OrderResponse): void {
    this.saveOrderValuesToLocalStorage(response);
    if (this.storage.free) {
      return this.updateAddressForFreeOrder();
    }

    this.dataLayerService.pushContactInfo(this.storage.patient.email, this.storage.patient.phone);
    this.router.navigateByUrl(this.orderPlacedRedirectPage);
  }

  /**
   * Update the shipping address
   *
   * @param {ShippingAddressStoreRequest} shippingAddressRequest The shipping address request
   * @param {string}                      transactionId          The transaction ID
   * @param {hash}                        hash                   The hash
   */
  storeShippingAddress(
    shippingAddressRequest: ShippingAddressStoreRequest,
    transactionId: string,
    hash: string
  ): Observable<ShippingAddressStoreResponse> {
    return this.http.post<ShippingAddressStoreResponse>(
      `${this.apiUrl}/api/v1/order/${transactionId}/shipping-address`,
      shippingAddressRequest,
      {
        headers: new HttpHeaders({ 'X-API-Hash': hash }),
      }
    );
  }

  /**
   * Extracts necessary information from the given order response and stores it in local storage for later use.
   *
   * @param {OrderResponse} orderResponse the API response when placing an order
   */
  saveOrderValuesToLocalStorage(orderResponse: OrderResponse): void {
    const patientControlValue = this.formService.checkout.get('patient').value;

    if (patientControlValue.phone) {
      this.storage.sendSMS = true;
    }

    this.storage.orderTotal = this.getTotal();
    this.storage.transactionId = orderResponse.transaction_id;
    this.storage.order = {
      has_better_lab: orderResponse.has_better_lab,
      email: orderResponse.email,
      date_of_birth: orderResponse.date_of_birth,
      hash: orderResponse.hash,
      first_name: orderResponse.first_name,
      last_name: orderResponse.last_name,
      gender: orderResponse.gender,
      transaction_id: orderResponse.transaction_id,
    };
    this.storage.email = orderResponse.email || patientControlValue.email;
    this.dataLayerService.pushDataLayerTracking(DataLayerCategories.Standard);
    this.storage.orderType = patientControlValue.contactVia;

    if (!this.storage.free) {
      this.storage.betterLab = !!orderResponse.has_better_lab;
    }

    if (this.storage.orderUpsells?.length) {
      this.dataLayerService.pushDataLayerTracking(DataLayerCategories.Upsell);
      this.storage.orderUpsells.map((upsell) => this.addUpsellTestToStorage(upsell));
    }
  }

  /**
   * Updates the address information for a free order.
   */
  private updateAddressForFreeOrder(): void {
    const addressContactInformation = this.formService.addressContactInformation;
    const addressUpdateRequest = new AddressUpdateRequest(addressContactInformation);
    addressUpdateRequest.agreeTerms = true;

    if (addressContactInformation.get('phone.sms_allowed').value) {
      this.storage.sendSMS = true;
    }

    this.storeAddress(addressUpdateRequest)
      .pipe(
        catchError((error) => {
          console.error(error);

          return of(null);
        })
      )
      .subscribe(() => {
        this.storage.addressComplete = true;
        this.router.navigateByUrl('/order-complete.php');
      });
  }
}
