import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { Ethnicities } from '@enums/ethnicities';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { Races } from '@enums/races';
import { SexWith } from '@enums/sex-with';
import { Symptom } from '@enums/symptoms';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';
import { dateValidator } from 'src/app/validators/date-validator';

@Component({
  selector: 'app-consultation-request-medical-history',
  templateUrl: './consultation-request-medical-history.component.html',
  styleUrls: ['./consultation-request-medical-history.component.scss'],
})
export class ConsultationRequestMedicalHistoryComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() isStdTreatmentType: boolean = false;

  medicalHistoryForm: FormGroup;

  ConsultationRequestService: typeof ConsultationRequestService = ConsultationRequestService;
  Pregnant: typeof QuestionnaireAnswers = QuestionnaireAnswers;
  SexWith: typeof SexWith = SexWith;
  Races: typeof Races = Races;
  Ethnicities: typeof Ethnicities = Ethnicities;

  menstrualCalendar: { months: Month[]; days: number[]; years: number[] };
  dueCalendar: { months: Month[]; days: number[]; years: number[] };

  private subscriptions: Subscription[] = [];

  constructor(
    private formService: FormService,
    private consultationRequestService: ConsultationRequestService,
    private sessionStorageService: SessionStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.medicalHistoryForm = this.formService.consultationRequest.get('medicalHistory') as FormGroup;

    this.setLastMenstrualFormOptions();
    this.setDueFormOptions();
    this.addFormValidators();
    this.subscriptions.push(this.listenForPregnantChanges());

    if (this.consultationRequestService.consultationOrderDetail) {
      this.prefillMedicalHistoryInformation();
    }
  }

  /**
   * Unsubscribe all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   *  Determines if is pregnant
   */
  get isPregnant(): boolean {
    return this.pregnantControl?.value === QuestionnaireAnswers.Yes;
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {string} controlName The name of the form control to check for validity.
   */
  invalidControl(controlName: string): boolean {
    return this.isInvalid(this.medicalHistoryForm.get(controlName));
  }

  /**
   * Deselect all symptoms if none is selected, or deselect none if any other
   * symptom is selected.
   *
   * @param event change event on checkbox label
   * @param symptom clicked symptom
   */
  onSymptomCheckboxChange(event: any, symptom: string): void {
    if (!event.target.checked) {
      return;
    }

    if (symptom === Symptom.None) {
      for (let controlKey in this.symptomsFormGroup.controls) {
        this.symptomsFormGroup.get(controlKey).setValue(symptom === controlKey);
      }
    } else {
      this.symptomsFormGroup.get(Symptom.None).setValue(false);
    }
  }

  /**
   * Determines if the gender is female
   */
  isGenderFemale(): boolean {
    return this.consultationRequestService.consultationOrderDetail?.gender === 'F';
  }

  /**
   * Determines if the field date is filled out
   */
  isDateFieldFilled(field: string): boolean {
    return ['month', 'day', 'year'].every((controlName: string) => {
      return !this.invalidControl(`${field}.${controlName}`);
    });
  }

  /**
   * Get the partner symptoms form group.
   */
  private get symptomsFormGroup(): FormGroup {
    return this.medicalHistoryForm?.get('symptoms') as FormGroup;
  }

  /**
   * Get the menstrual date form group.
   */
  private get menstrualDateFormGroup(): FormGroup {
    return this.medicalHistoryForm?.get('menstrual_d') as FormGroup;
  }

  /**
   * Get the due date form group.
   */
  private get dueDateFormGroup(): FormGroup {
    return this.medicalHistoryForm?.get('due_d') as FormGroup;
  }

  /**
   * Get the race control.
   */
  private get raceControl(): FormControl {
    return this.medicalHistoryForm.get('race') as FormControl;
  }

  /**
   * Get the ethnicity control.
   */
  private get ethnicityControl(): FormControl {
    return this.medicalHistoryForm.get('ethnicity') as FormControl;
  }

  /**
   * Get the pregnant control.
   */
  private get pregnantControl(): FormControl {
    return this.medicalHistoryForm?.get('pregnant') as FormControl;
  }

  /**
   * Set the last menstrual date form options
   */
  private setLastMenstrualFormOptions(): void {
    this.menstrualCalendar = {
      months: Months,
      days: this.getListOfNumbers(1, 31),
      years: this.getListOfNumbers(new Date().getFullYear(), new Date().getFullYear() - 59),
    };
  }

  /**
   * Set the due date form options
   */
  private setDueFormOptions(): void {
    this.dueCalendar = {
      months: Months,
      days: this.getListOfNumbers(1, 31),
      years: this.getListOfNumbers(new Date().getFullYear() + 1, new Date().getFullYear()),
    };
  }

  /**
   * Prefill medical history information if we already have the data coming from the order
   */
  private prefillMedicalHistoryInformation(): void {
    ['race', 'ethnicity', 'sex_with', 'pregnant'].forEach((key) => {
      this.medicalHistoryForm.get(key).setValue(this.consultationRequestService.consultationOrderDetail[key]);
    });

    const menstrualDate = this.splitDateIntoPieces(
      this.consultationRequestService.consultationOrderDetail.last_menstrual_date
    );
    this.setFormGroupDate(this.menstrualDateFormGroup, menstrualDate);

    const dueDate = this.splitDateIntoPieces(this.consultationRequestService.consultationOrderDetail.due_date);
    this.setFormGroupDate(this.dueDateFormGroup, dueDate);

    this.consultationRequestService.consultationOrderDetail.symptoms.split(',').forEach((symptomKey) => {
      this.symptomsFormGroup.get(Symptom[symptomKey])?.setValue(true);
    });
  }

  /**
   * Add the validator functions to the form.
   */
  private addFormValidators(): void {
    if (this.isStdTreatmentType) {
      this.raceControl.addValidators(Validators.required);
      this.raceControl.updateValueAndValidity();
      this.ethnicityControl.addValidators(Validators.required);
      this.ethnicityControl.updateValueAndValidity();
    }

    if (this.isGenderFemale()) {
      this.pregnantControl.addValidators(Validators.required);
      this.pregnantControl.updateValueAndValidity();
      this.setDatesValidators();
    }

    this.symptomsFormGroup.addValidators(this.formService.symptomSelectedValidator());
  }

  /**
   * Toggle date required validator between form group
   */
  private toggleDateValidator(removeValidatorsFrom: FormGroup, addValidatorsTo: FormGroup): void {
    ['month', 'day', 'year'].forEach((item) => {
      addValidatorsTo.get(item).addValidators(Validators.required);
      removeValidatorsFrom.get(item).clearValidators();
    });

    addValidatorsTo.addValidators(dateValidator());
    addValidatorsTo.updateValueAndValidity();

    removeValidatorsFrom.clearValidators();
    removeValidatorsFrom.reset();
    removeValidatorsFrom.updateValueAndValidity();
  }

  /**
   * Update the validators of the date of the last menstruation and the due date according
   * to the pregnancy status of the patient.
   */
  private setDatesValidators(): void {
    this.isPregnant
      ? this.toggleDateValidator(this.menstrualDateFormGroup, this.dueDateFormGroup)
      : this.toggleDateValidator(this.dueDateFormGroup, this.menstrualDateFormGroup);
  }

  /**
   * Listen for changes in the pregnant value to determine whether to add or clear the
   * validators.
   *
   * @returns a Subscription to changes in the form's treatment value
   */
  private listenForPregnantChanges(): Subscription {
    return this.pregnantControl.valueChanges.subscribe((value) => {
      if (!Object.values(QuestionnaireAnswers).includes(value)) {
        return;
      }

      this.setDatesValidators();
    });
  }

  /**
   * Set the form group date values
   *
   * @param formGroup formGroup that we need to set the date values
   * @param date date to be set in the formGroup
   */
  private setFormGroupDate(formGroup: FormGroup, date: { month: string; day: string; year: string }): void {
    formGroup.get('month').setValue(date.month);
    formGroup.get('day').setValue(date.day);
    formGroup.get('year').setValue(date.year);
  }
}
