import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';
import { SelectableOption } from '@models/selectable-option';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf],
})
export class CheckboxInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;

  /**
   * Gets the form array for the question's form control.
   *
   * @returns {FormArray} the form array
   */
  get formArray(): FormArray {
    return this.form.get(this.question.id) as FormArray;
  }

  /**
   * Handles the change event for a checkbox input.
   *
   * @param {Event} event the change event from the checkbox
   */
  onCheckboxChange(event: Event): void {
    this.formArray.markAllAsTouched();
    const { checked, value } = event.target as HTMLInputElement;
    if (checked) {
      this.handleCheckboxSelection(value);

      return;
    }

    this.deselectOption(value);
  }

  /**
   * Handles the logic when a checkbox is selected.
   *
   * @param {string} value the value of the selected checkbox
   */
  private handleCheckboxSelection(value: string): void {
    const selectedOption = this.findOption(value);

    if (selectedOption?.exclusive) {
      this.selectExclusiveOption(value);

      return;
    }

    this.selectNonExclusiveOption(value);
  }

  /**
   * Selects an exclusive option, clearing all other selections.
   *
   * @param {string} value the value of the exclusive option to select
   */
  private selectExclusiveOption(value: string): void {
    this.formArray.clear();
    this.formArray.push(new FormControl(value));
  }

  /**
   * Selects a non-exclusive option, removing any selected exclusive options.
   *
   * @param {string} value the value of the non-exclusive option to select
   */
  private selectNonExclusiveOption(value: string): void {
    this.deselectExclusiveOptions();
    this.formArray.push(new FormControl(value));
  }

  /**
   * Removes all exclusive options from the form array.
   *
   * The indexes are reversed before removal to maintain the correct positions, since removing elements from lower indexes
   * would shift the positions of higher ones.
   */
  private deselectExclusiveOptions(): void {
    const exclusiveIndexes = this.formArray.controls
      .map((control, index) => ({
        index,
        isExclusive: this.findOption(control.value)?.exclusive,
      }))
      .filter((item) => item.isExclusive)
      .map((item) => item.index)
      .reverse();

    exclusiveIndexes.forEach((index) => this.formArray.removeAt(index));
  }

  /**
   * Removes a specific option from the form array.
   *
   * @param {string} value the value of the option to remove
   */
  private deselectOption(value: string): void {
    const index = this.formArray.controls.findIndex((control) => control.value === value);
    if (index > -1) {
      this.formArray.removeAt(index);
    }
  }

  /**
   * Finds the SelectableOption object for a given value.
   *
   * @param {string} value the value to search for
   *
   * @returns {SelectableOption | undefined} the found option or undefined
   */
  private findOption(value: string): SelectableOption | undefined {
    return this.question.options.find((option) => option.value === value);
  }

  /**
   * Determines if the checkbox should be checked based on the provided value.
   *
   * @param {string} value the value to check against the form controls
   *
   * @returns {boolean} true if the checkbox should be checked, false otherwise
   */
  shouldCheckCheckbox(value: string): boolean {
    return this.formArray.controls.some((control) => control.value === value);
  }
}
