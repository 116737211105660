import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InHomeCollectionService, ResultService } from '@Medology/ng-findalab';
import { TreatmentConsultationRequest } from '@models/consultation-request/treatment-consultation-request';
import { LoadCartOptions } from '@models/load-cart-options';
import { OrderResponse } from '@models/order-response';
import { PlaceOrderConsultationRequest } from '@models/place-order-consultation-request';
import { PlaceOrderRequest } from '@models/place-order-request';
import { STDcheckLoadCartResponse } from '@models/stdcheck-load-cart-response';
import { TmuPlaceOrderRequest } from '@models/tmu-place-order-request';
import { TreatMyUTILoadCartRequest } from '@models/treatmyuti-load-cart-request';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { DomainService } from '@services/domain.service';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { SessionStorageService } from '@services/session-storage.service';
import { StorageService } from '@services/storage.service';
import { finalize, firstValueFrom, Observable, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderConsultationRequestService extends OrderService {
  protected override orderPlacedRedirectPage: string = '/order-complete.php';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected router: Router,
    protected storage: StorageService,
    protected results: ResultService,
    protected formService: FormService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected inHomeService: InHomeCollectionService,
    protected dataLayerService: DataLayerService,
    protected sessionStorageService: SessionStorageService
  ) {
    super(
      config,
      document,
      http,
      router,
      storage,
      results,
      formService,
      domainService,
      authService,
      inHomeService,
      dataLayerService,
      sessionStorageService
    );
  }

  /**
   * Gets the coupon code from the storage.
   */
  override get couponCode(): string | null {
    return this.storage.coupon?.coupon_code || null;
  }

  /**
   * Avoids the order completed request to be sent.
   */
  override orderCompleted(): Observable<any> {
    return of({});
  }

  /** {@inheritDoc OrderService} */
  override listenForLabSelection(): Subscription {
    return of({}).subscribe();
  }

  /** {@inheritDoc OrderService} */
  override loadCart(options?: LoadCartOptions): Observable<STDcheckLoadCartResponse> {
    const request = new TreatMyUTILoadCartRequest({
      tests: this.storage.tests,
      coupon: this.couponCode,
    });

    return this.loadCartRequest(request);
  }

  /** {@inheritDoc OrderService} */
  override parseCartResponse(response: STDcheckLoadCartResponse): Observable<STDcheckLoadCartResponse> {
    return this.parseCoupon(response);
  }

  /**
   * Submits the order & consultation request.
   *
   * @param {string} deviceData the device data from Braintree
   */
  override submitOrder(deviceData: string): Promise<OrderResponse> {
    const orderRequest = new PlaceOrderRequest(this.getOrderRequest(deviceData));
    orderRequest.site_name = this.domainService.getSiteDomain();
    const request = new PlaceOrderConsultationRequest(orderRequest, this.createConsultationRequest());

    return firstValueFrom(
      this.http
        .post<OrderResponse>(
          `${this.apiUrl}/api/v1/order-consultation`,
          request,
          this.authService.getOptionsWithAuthHeader()
        )
        .pipe(
          finalize(() => {
            this.storage.treatmentType = request.consult.treatment_type;
            this.storage.addressComplete = true;
          })
        )
    );
  }

  /** {@inheritDoc OrderService} */
  protected override getTotalDiscount(): number {
    return 0;
  }

  /**
   * Gets the order request with additional data.
   *
   * @param {string} deviceData the device data for the order request
   *
   * @returns {PlaceOrderRequest} the customized order request
   */
  protected override getOrderRequest(deviceData: string): PlaceOrderRequest {
    const placeOrderRequest = super.getOrderRequest(deviceData) as TmuPlaceOrderRequest;
    placeOrderRequest.tests = this.storage.tests.map((test) => test.id);
    placeOrderRequest.coupon = this.storage.coupon?.coupon_code ?? null;
    placeOrderRequest.lab_id = +this.config.defaultOrderLabId;
    placeOrderRequest.address = this.storage.center.address;
    placeOrderRequest.city = this.storage.center.city;
    placeOrderRequest.state = this.storage.center.state;
    placeOrderRequest.zip = this.storage.center.zip_code;

    return placeOrderRequest;
  }

  /**
   * Creates a TreatmentConsultationRequest.
   *
   * @returns {TreatmentConsultationRequest} the consultation request to be sent
   */
  private createConsultationRequest(): TreatmentConsultationRequest {
    return new TreatmentConsultationRequest(
      this.formService.checkout.get('patient') as FormGroup,
      this.formService.checkout.get('medicalHistory') as FormGroup,
      this.storage.center.id,
      this.storage.treatmentType
    );
  }
}
