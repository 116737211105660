import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ConsultationErrorPageComponent } from '@components/consultation-request/error-page/consultation-error-page.component';
import { StdPreventionConsultationRequestCompletedComponent } from '@components/doxy-pep/std-prevention-consultation-request-completed/std-prevention-consultation-request-completed.component';
import { StdConsultationRequestCompletedComponent } from '@components/std-consultation-request-completed/std-consultation-request-completed.component';
import { TreatmentConsultationRequestCompletedComponent } from '@components/treatment-consultation-request-completed/treatment-consultation-request-completed.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestCompletedGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private domainService: DomainService
  ) {}

  /**
   * Determines if the user can activate a particular route based on the current consultation treatment type.
   * Additionally, assigns the corresponding component to the route based on the treatment type.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   *
   * @returns {boolean} whether the route can be activated
   */
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const treatmentType = route.params.treatmentType?.replace(/-/g, '_');
    const component = this.getComponent(treatmentType);
    if (!component) {
      this.document.location.href = this.config.siteUrls.tests;

      return false;
    }

    route.component = component;

    return true;
  }

  /**
   * Retrieves the component corresponding to the given treatment type.
   *
   * @param {string} treatmentType the type of treatment for the consultation
   *
   * @returns {any} the component corresponding to the treatment type or null if no component is found
   */
  private getComponent(treatmentType: string): any {
    switch (treatmentType) {
      case ConsultationTreatmentTypes.Uti:
      case ConsultationTreatmentTypes.YeastInfection:
      case ConsultationTreatmentTypes.BacterialVaginosis:
      case ConsultationTreatmentTypes.Hsv:
      case ConsultationTreatmentTypes.Metformin:
        return TreatmentConsultationRequestCompletedComponent;
      case ConsultationTreatmentTypes.StdPrevention:
        if (this.domainService.isStarfishDomain()) {
          return TreatmentConsultationRequestCompletedComponent;
        }

        return StdPreventionConsultationRequestCompletedComponent;
      case ConsultationTreatmentTypes.Std:
        if (this.domainService.isStarfishDomain()) {
          return TreatmentConsultationRequestCompletedComponent;
        }

        return StdConsultationRequestCompletedComponent;
      default:
        return ConsultationErrorPageComponent;
    }
  }
}
