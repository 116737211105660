import { Component } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-mens-intimate-wash',
  templateUrl: './mens-intimate-wash.component.html',
  styleUrl: './mens-intimate-wash.component.scss',
})
export class MensIntimateWashComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  readonly slug: UpsellSlugs = UpsellSlugs.MensIntimateWash;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }

  /**
   * Gets the question text based on the treatment type.
   *
   * @returns {string} the question text to display
   */
  get question(): string {
    return this.treatmentType === ConsultationTreatmentTypes.Hsv
      ? 'We highly recommend including the Penile Wash to support healing and reduce the risk of spread or outbreaks. Would you like to add it to your order?'
      : 'We highly recommend including the Mens Intimate Wash to prevent reinfection. Would you like to add it to your order?';
  }

  /**
   * Gets the HTML title based on the treatment type.
   *
   * @returns {string} the HTML title to display
   */
  get htmlTitle(): string {
    return this.treatmentType === ConsultationTreatmentTypes.Hsv
      ? '<strong>Essential: Support Healing</strong> and Manage<br>Outbreaks Effectively'
      : '<strong>Important: Prevent Reinfection</strong> and Ensure<br>Lasting Relief';
  }
}
