import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { DataLayerCategories } from '@enums/data-layer-categories';
import { NewConsultationRequestResponse } from '@models/consultation-request/new-consultation-request-response';
import { DataLayerResponse } from '@models/data-layer';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { StorageService } from '@services/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * Handles data layer requests.
 */
export class DataLayerService {
  private window;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    this.window = this.document.defaultView;
  }

  /**
   * Get the data layer data of the order.
   *
   * @param {DataLayerCategories} category category to get the data layer
   */
  private getDataLayer(category: DataLayerCategories): Observable<DataLayerResponse> {
    return this.http
      .get<DataLayerResponse>(
        `${this.config.analyteCareApi}/api/v1/order-data-layer?transaction_id=${this.storageService.transactionId}&category=${category}`
      )
      .pipe(
        map((dataLayerResponse) => {
          dataLayerResponse.transactionId =
            category === DataLayerCategories.Upsell
              ? dataLayerResponse.transactionId + '_upsell'
              : dataLayerResponse.transactionId;

          return dataLayerResponse;
        })
      );
  }

  /**
   * Push the Data Layer Category into the Ecommerce Data Layer Categories Tracking, preventing duplicates.
   *
   * @param {DataLayerCategories} dataLayerCategories data layer category to be pushed
   */
  pushDataLayerTracking(dataLayerCategories: DataLayerCategories): void {
    const dataLayerTracking = this.storageService.ecommerceTrackingCategories;
    dataLayerTracking.push(dataLayerCategories);
    this.storageService.ecommerceTrackingCategories = [...new Set(dataLayerTracking)];
  }

  /**
   * Handle and set data layer structure
   */
  handleDataLayer(): void {
    if (!this.storageService.transactionId) {
      return;
    }

    if (!this.storageService.ecommerceTrackingCategories.length) {
      return;
    }

    this.storageService.ecommerceTrackingCategories.forEach((category: DataLayerCategories) => {
      this.getDataLayer(category).subscribe((dataLayer) => {
        this.window.dataLayer.push(dataLayer);
      });
    });

    this.storageService.ecommerceTrackingCategories = [];
  }

  /**
   * Push element with email and phone information to the data layer.
   *
   * @param email email address to be stored in the datalayer
   * @param phone phone to be stored in the datalayer
   */
  pushContactInfo(email: string, phone: string): void {
    this.window.dataLayer.push({ email: email, phone: phone });
  }

  /**
   * Push element with the information from the consultation request to the data layer.
   *
   * @param newConsultationRequest new consultation request response
   * @param treatmentType treatment type of the consultation
   * @param transactionId transaction id from the order
   */
  addConsultationRequestPurchaseToDataLayer(
    newConsultationRequest: NewConsultationRequestResponse,
    treatmentType: ConsultationTreatmentTypes,
    transactionId: string
  ): void {
    const dataLayerElement = {
      event: 'ecomm_event',
      transactionId: `${transactionId}_${treatmentType}_consultation`,
      transactionTotal: newConsultationRequest.payment_amount / 100,
      transactionProducts: newConsultationRequest.line_items
        .filter((item) => item.type !== 'Discount')
        .map((item) => ({
          sku: 'consultation',
          name: item.name,
          category: 'consultation',
          price: item.price / 100,
          quantity: 1,
        })),
    };

    if (this.window.dataLayer) {
      this.window.dataLayer.push(dataLayerElement);
    } else {
      this.window.dataLayer = [dataLayerElement];
    }
  }
}
