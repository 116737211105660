import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { ConsultAttachmentComponent } from '@components/consultation-request/consult-attachment/consult-attachment.component';
import { ConsultationRequestPageComponent } from '@components/consultation-request/consultation-request-page/consultation-request-page.component';
import { ConsultationUpsellPaymentCardComponent } from '@components/consultation-request/consultation-upsell-payment-card/consultation-upsell-payment-card.component';
import { CookiesDisabledComponent } from '@components/cookies-disabled/cookies-disabled.component';
import { DoxyPEPComponent } from '@components/doxy-pep/doxy-pep.component';
import { MycoplasmaGenitaliumComponent } from '@components/mycoplasma-genitalium/mycoplasma-genitalium.component';
import { NullComponent } from '@components/null/null.component';
import { OrderComponent } from '@components/order/order.component';
import { OrderAddressComponent } from '@components/order-address/order-address.component';
import { OrderBetterLabComponent } from '@components/order-better-lab/order-better-lab.component';
import { OrderCompleteComponent } from '@components/order-complete/order-complete.component';
import { PartialResultsDeliveryComponent } from '@components/partial-results-delivery/partial-results-delivery.component';
import { ScheduleConsultationComponent } from '@components/schedule-consultation/schedule-consultation.component';
import { AlleviateNauseaAndVomitingComponent } from '@consultation-upsells/alleviate-nausea-and-vomiting/alleviate-nausea-and-vomiting.component';
import { BePreparedForTheUnexpectedComponent } from '@consultation-upsells/be-prepared-for-the-unexpected/be-prepared-for-the-unexpected.component';
import { ClotrimazoleVaginalCreamComponent } from '@consultation-upsells/clotrimazole-vaginal-cream/clotrimazole-vaginal-cream.component';
import { FollowUpStdTestComponent } from '@consultation-upsells/follow-up-std-test/follow-up-std-test.component';
import { MedicationPickupDeliveryComponent } from '@consultation-upsells/medication-pickup-delivery/medication-pickup-delivery.component';
import { MensIntimateWashComponent } from '@consultation-upsells/mens-intimate-wash/mens-intimate-wash.component';
import { NeedADoctorsNoteComponent } from '@consultation-upsells/need-a-doctors-note/need-a-doctors-note.component';
import { RelieveOutbreakPainComponent } from '@consultation-upsells/relieve-outbreak-pain/relieve-outbreak-pain.component';
import { ReplenishYourGoodBacteriaComponent } from '@consultation-upsells/replenish-your-good-bacteria/replenish-your-good-bacteria.component';
import { VaginalCreamComponent } from '@consultation-upsells/vagnial-cream/vaginal-cream.component';
import { VitaminDSupplementComponent } from '@consultation-upsells/vitamin-d-supplement/vitamin-d-supplement.component';
import { YeastInfectionPreventionComponent } from '@consultation-upsells/yeast-infection-prevention/yeast-infection-prevention.component';
import { BetterLabGuard } from '@guards/better-lab.guard';
import { ConsultationPharmacyStateGuard } from '@guards/consultation-pharmacy-state.guard';
import { ConsultationRequestCompletedGuard } from '@guards/consultation-request-completed.guard';
import { ConsultationRequestSessionGuard } from '@guards/consultation-request-session.guard';
import { ConsultationUpsellGuard } from '@guards/consultation-upsell.guard';
import { ConsultationUpsellPaymentGuard } from '@guards/consultation-upsell-payment.guard';
import { OrderGuard } from '@guards/order.guard';
import { OrderAddressGuard } from '@guards/order-address.guard';
import { OrderCompleteGuard } from '@guards/order-complete.guard';
import { RecentMenstruationRequiredGuard } from '@guards/recent-menstruation-required.guard';
import { TransactionIdGuard } from '@guards/transaction-id.guard';
import { VaginalCreamAllowedGuard } from '@guards/vaginal-cream-allowed-guard.service';
import { WhitelistGuard } from '@guards/whitelist.guard';
import { ConsultationRequestResolver } from '@resolvers/consultation-request.resolver';

/**
 * Routes module that will map all routes with the appropriate layouts or child layouts
 */
const routes: Routes = [
  {
    path: 'null',
    component: NullComponent,
    pathMatch: 'full',
  },
  {
    path: 'order',
    component: OrderComponent,
    pathMatch: 'full',
    canActivate: [OrderGuard],
  },
  {
    path: 'order-address.php',
    component: OrderAddressComponent,
    pathMatch: 'full',
    canActivate: [OrderAddressGuard],
  },
  {
    path: 'order-complete.php',
    component: OrderCompleteComponent,
    pathMatch: 'full',
    canActivate: [OrderCompleteGuard],
  },
  {
    path: 'betterlab.php',
    component: OrderBetterLabComponent,
    pathMatch: 'full',
    canActivate: [BetterLabGuard],
  },
  {
    path: ':treatmentType/consultation-request-completed',
    component: NullComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestCompletedGuard],
  },
  {
    path: 'consultation/schedule-consultation',
    component: ScheduleConsultationComponent,
    pathMatch: 'full',
  },
  {
    path: 'consultation/medication-pickup-or-delivery',
    component: MedicationPickupDeliveryComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard, ConsultationPharmacyStateGuard],
  },
  {
    path: 'consultation/upload-consult-attachments',
    component: ConsultAttachmentComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard],
  },
  {
    path: 'doxy-pep-treatment',
    component: DoxyPEPComponent,
    pathMatch: 'full',
    canActivate: [TransactionIdGuard],
  },
  {
    path: 'partial-results-delivery',
    component: PartialResultsDeliveryComponent,
    pathMatch: 'full',
    canActivate: [TransactionIdGuard],
  },
  {
    path: 'mycoplasma-genitalium',
    component: MycoplasmaGenitaliumComponent,
    pathMatch: 'full',
    canActivate: [TransactionIdGuard],
  },
  {
    path: 'consultation/yeast-infection-prevention',
    component: YeastInfectionPreventionComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/alleviate-nausea-and-vomiting',
    component: AlleviateNauseaAndVomitingComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/replenish-your-good-bacteria',
    component: ReplenishYourGoodBacteriaComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/mens-intimate-wash',
    component: MensIntimateWashComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/need-a-doctors-note',
    component: NeedADoctorsNoteComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/relieve-outbreak-pain',
    component: RelieveOutbreakPainComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/be-prepared-for-the-unexpected',
    component: BePreparedForTheUnexpectedComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard, RecentMenstruationRequiredGuard],
  },
  {
    path: 'consultation/vaginal-cream',
    component: VaginalCreamComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard, VaginalCreamAllowedGuard],
  },
  {
    path: 'consultation/clotrimazole-vaginal-cream',
    component: ClotrimazoleVaginalCreamComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/follow-up-std-test',
    component: FollowUpStdTestComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/payment-info',
    component: ConsultationUpsellPaymentCardComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard, ConsultationUpsellPaymentGuard],
  },
  {
    path: 'consultation/vitamin-d-supplement',
    component: VitaminDSupplementComponent,
    pathMatch: 'full',
    canActivate: [ConsultationRequestSessionGuard, ConsultationUpsellGuard],
  },
  {
    path: 'consultation/:treatmentType/request',
    component: ConsultationRequestPageComponent,
    resolve: {
      consultationOrderDetails: ConsultationRequestResolver,
    },
    pathMatch: 'full',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'order',
  },
]
  .map(requireCookies)
  .map(requireRouteToBeWhitelisted);

/**
 * Ensures that the WhitelistGuard is added to the route's canActivate array if it is not already
 * present and the route does not redirect to another route.
 *
 * @param {Route} route the original route
 *
 * @returns {Route} the route with the WhitelistGuard added
 */
function requireRouteToBeWhitelisted(route: Route): Route {
  if (!route.redirectTo && !route.canActivate?.includes(WhitelistGuard)) {
    route.canActivate = [WhitelistGuard, ...(route.canActivate || [])];
  }

  return route;
}

/**
 * Makes a route require cookies. If cookies are not enabled, the route's component is replaced with
 * CookiesDisabledComponent. Additionally, any route guards defined in `canActivate` will be removed.
 *
 * @param {any} route the original route
 *
 * @returns {Route} the route with cookies required
 */
function requireCookies(route: any): Route {
  if (navigator.cookieEnabled && isLocalStorageAvailable()) {
    return route;
  }

  if (route.component) {
    route.component = CookiesDisabledComponent;
  }

  if (route.canActivate) {
    route.canActivate = [];
  }

  return route;
}

/**
 * Checks if local storage is available by attempting to set and remove an item.
 *
 * @returns {boolean} true if local storage is available, false otherwise
 */
function isLocalStorageAvailable(): boolean {
  try {
    localStorage.setItem('is-local-storage-available', 'test');
    localStorage.removeItem('is-local-storage-available');

    return true;
  } catch (error) {
    return false;
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})

/**
 * Exports the whole application routing module.
 */
export class AppRoutingModule {}
