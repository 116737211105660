import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestError } from '@errors/consultation-request-error';
import { ConsultationRequestExpiredSessionError } from '@errors/consultation-request-expired-session-error';
import { ConsultationRequestInvalidLinkError } from '@errors/consultation-request-invalid-link-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestResolver {
  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private loadingService: LoadingService,
    private sessionStorageService: SessionStorageService,
    private domainService: DomainService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.loadingService.toggleLoader(true);
    let treatmentType: ConsultationTreatmentTypes = route.params.treatmentType.replace(/-/g, '_');
    if (treatmentType === ConsultationTreatmentTypes.Std && this.domainService.isStarfishDomain()) {
      treatmentType = ConsultationTreatmentTypes.StarfishStd;
    }
    this.sessionStorageService.treatmentType = treatmentType;

    if (!route.queryParams.order_id || !route.queryParams.hash) {
      const error = new ConsultationRequestExpiredSessionError();

      return of({ error, errorMessage: error.message });
    }

    return this.consultationRequestService
      .getConsultationOrderDetails(
        route.queryParams.order_id,
        decodeURIComponent(route.queryParams.hash),
        treatmentType
      )
      .pipe(
        tap(() => this.saveToSessionStorage(this.consultationRequestService.consultationOrderDetail)),
        catchError((error: any) => {
          let errorMessage =
            error instanceof ConsultationRequestError
              ? error.message
              : new ConsultationRequestInvalidLinkError(this.config.email).message;

          if (
            error?.error?.code === 403 &&
            error?.error?.message &&
            !error.error.message.includes('We are very sorry, it seems like there was a problem.')
          ) {
            errorMessage = error.error.message;
          }

          //@todo remove this after fixing the hash problem. Issue: https://github.com/Medology/ng-checkout/issues/2263
          console.error(`Consultation Request Error: ${error.message}`, {
            transactionId: route.queryParams.order_id ?? 'transactionId not found',
            hash: route.queryParams.hash ?? 'hash not found',
            decodedHash: decodeURIComponent(route.queryParams.hash) ?? 'hash not found',
          });

          return of({ error, errorMessage });
        })
      );
  }

  /**
   * Saves the consultation order details to the session storage.
   *
   * @param {ConsultationRequestOrderDetail} consultationOrderDetail The consultation order details.
   */
  private saveToSessionStorage(consultationOrderDetail: ConsultationRequestOrderDetail) {
    this.sessionStorageService.orderedTests = consultationOrderDetail.tests;
    this.sessionStorageService.recharge = consultationOrderDetail.recharge;
    this.sessionStorageService.patient = {
      name: consultationOrderDetail.first_name,
      last_name: consultationOrderDetail.last_name,
      email: consultationOrderDetail.email,
      phone: consultationOrderDetail.phone,
    };
  }
}
