export enum ConsultationTreatmentTypes {
  BacterialVaginosis = 'bacterial_vaginosis',
  Nurse = 'nurse',
  StarfishStd = 'starfish_std',
  Std = 'std',
  StdPrevention = 'std_prevention',
  Uti = 'uti',
  VaginalDryness = 'vaginal_dryness',
  YeastInfection = 'yeast_infection',
  Hsv = 'hsv',
  Metformin = 'metformin',
}
