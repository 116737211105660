import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  /**
   * Allows errors to bubble up so that any project using ng-checkout can handle Angular-related errors as desired.
   *
   * @param {any} error the error to handle
   */
  handleError(error: any): void {
    if (!(error instanceof HttpErrorResponse)) {
      throw error;
    }

    console.error('HTTP Error: ' + error.message, {
      message: error.message,
      status: error.status,
      statusText: error.statusText,
      url: error.url,
    });
  }
}
