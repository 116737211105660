import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { PaymentTypes } from '@enums/payment-types';
import { environment } from '@environments/environment.prod';
import { ConsultationRequestPayment } from '@models/consultation-request/consultation-request-payment';
import { NewConsultationRequestResponse } from '@models/consultation-request/new-consultation-request-response';
import { StdPreventionConsultationRequest } from '@models/consultation-request/std-prevention/std-prevention-consultation-request';
import { DoxyPepFaq } from '@models/doxy-pep-faq';
import { DoxyPepResponse } from '@models/doxy-pep-response';
import { OrderResponse } from '@models/order-response';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DataLayerService } from '@services/data-layer.service';
import { DoxyPepService } from '@services/doxy-pep.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-doxy-pep',
  templateUrl: './doxy-pep.component.html',
  styleUrls: ['./doxy-pep.component.scss'],
})
export class DoxyPEPComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  doxyPepContent: DoxyPepResponse;
  doxyPepForm: FormGroup;
  shouldExpand: boolean = false;
  showPaymentInformation: boolean = false;
  submissionErrors: string[] = [];
  processing: boolean = false;
  rechargeAttempt: boolean = false;

  /** The DoxyPep test. */
  readonly doxyPepTest: OrderUpsell = environment.doxyPepUpsell;

  /**
   * Gets the price of the DoxyPep test.
   */
  get doxyPepPrice(): number {
    return Number(this.doxyPepContent.price);
  }

  /**
   * Gets the order details.
   */
  get order(): OrderResponse {
    return this.storageService.order;
  }

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private router: Router,
    private storageService: StorageService,
    private orderService: OrderService,
    private errorHandlerService: ErrorHandlerService,
    private doxyPepService: DoxyPepService,
    private loadingService: LoadingService,
    private consultationRequestService: ConsultationRequestService,
    private braintreeService: BraintreeService,
    private datalayerService: DataLayerService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.getDoxyPepContent();
  }

  /**
   * Toggles the content of the DoxyPep content.
   */
  toggleContent(): void {
    this.shouldExpand = !this.shouldExpand;
  }

  /**
   * Toggles the content of the DoxyPep FAQ.
   *
   * @param { DoxyPepFaq } faq the FAQ to toggle
   */
  toggleFAQ(faq: DoxyPepFaq): void {
    faq.isExpanded = !faq.isExpanded;
  }

  /**
   * Determine workflow to perform recharge or manual payment.
   */
  submit(): void {
    this.submissionErrors = [];
    this.processing = true;

    if (this.storageService.paymentType === PaymentTypes.PayLater && !this.rechargeAttempt) {
      this.displayPaymentInformation();
      this.rechargeAttempt = true;
      this.processing = false;

      return;
    }

    this.submitConsultationRequest();
  }

  /**
   * Redirects to the order complete page.
   */
  redirectOrderComplete(): void {
    this.router.navigateByUrl('order-complete.php');
  }

  /**
   * Places a consultation request order.
   */
  submitConsultationRequest(): void {
    const stdPreventionConsultationRequest = new StdPreventionConsultationRequest();
    const orderId = this.order.transaction_id;

    stdPreventionConsultationRequest.payment = this.getPayment();
    stdPreventionConsultationRequest.setAttributesFromOrder(this.order);
    stdPreventionConsultationRequest.phone = this.storageService.patient.phone;
    this.processing = false;

    this.consultationRequestService
      .postConsultationRequest(stdPreventionConsultationRequest, this.order.hash, orderId)
      .subscribe({
        next: (response) => this.handleSuccessfulStoreUpsell(response, orderId),
        error: (error) => {
          if (this.showPaymentInformation) {
            this.placeOrderComponent.processing = false;
          }

          this.handleRequestError(error, this.showPaymentInformation);

          if (!this.showPaymentInformation) {
            this.displayPaymentInformation();
          }
        },
      });
  }

  /**
   * Gets the payment information for the consultation request.
   */
  private getPayment(): ConsultationRequestPayment {
    if (!this.rechargeAttempt) {
      this.rechargeAttempt = true;

      return new ConsultationRequestPayment(
        { payment: { method: 'recharge' } },
        null,
        this.braintreeService.deviceData
      );
    }

    return new ConsultationRequestPayment(this.doxyPepForm.value, null, this.braintreeService.deviceData);
  }

  /**
   * Displays the payment information.
   */
  private displayPaymentInformation(): void {
    this.initPaymentForm();
    this.showPaymentInformation = true;
  }

  /**
   * Initializes the additional recommended testing form.
   */
  private initPaymentForm(): void {
    this.doxyPepForm = this.formBuilder.group({
      tests: new FormGroup({}),
      payment: this.formService.getNewPaymentForm(),
    });
    (this.doxyPepForm.controls.tests as FormGroup).addControl(this.doxyPepTest.slug, new FormControl(true));
  }

  /**
   * Handler for the StoreUpsell request when it succeeds.
   *
   * @param { NewConsultationRequestResponse } consultationStoreResponse the successful response object
   */
  private handleSuccessfulStoreUpsell(
    consultationStoreResponse: NewConsultationRequestResponse,
    orderId: string
  ): void {
    this.orderService.addUpsellTestToStorage(this.doxyPepTest);
    this.orderService.addUpsellPriceToTotal(this.doxyPepTest.upsell_price);
    this.datalayerService.addConsultationRequestPurchaseToDataLayer(
      consultationStoreResponse,
      ConsultationTreatmentTypes.StdPrevention,
      orderId
    );
    this.processing = false;
    this.redirectOrderComplete();
  }

  /**
   * General purpose error handler for failing requests
   *
   * @param { HttpErrorResponse } error The response error object
   * @param { boolean } setSubmissionErrors Whether to set the submission errors or not
   */
  private handleRequestError(error: HttpErrorResponse, setSubmissionErrors: boolean = true): void {
    this.processing = false;
    this.submissionErrors = setSubmissionErrors ? this.errorHandlerService.handleResponseError(error) : [];
  }

  /**
   * Fetches the content for the DoxyPep component.
   */
  private getDoxyPepContent(): void {
    this.loadingService.toggleLoader(true);
    this.doxyPepService.getDoxyPepContent().subscribe({
      next: (response) => {
        this.doxyPepContent = response;
        this.doxyPepContent.faq_content.faqs.map((faq) => (faq.isExpanded = false));
        this.loadingService.toggleLoader(false);
      },
      error: (err) => {
        this.loadingService.toggleLoader(false);
        setTimeout(() => this.redirectOrderComplete(), 0);
      },
    });
  }
}
