import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Gender } from '@enums/gender';
import { LastMenstrualPeriod } from '@enums/last-menstrual-period';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RecentMenstruationRequiredGuard implements CanActivate {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private navigationService: NavigationService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Promise<boolean | UrlTree>} a promise that resolves to true if the route can be activated, or a UrlTree
   * to redirect otherwise
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.isPatientGender(Gender.Male) || this.isPostMenopausal()) {
      const nextPageUrl = await this.navigationService.getConsultationRequestNextPageUrl(
        this.sessionStorageService.treatmentType,
        state.url
      );

      return this.router.createUrlTree([nextPageUrl]);
    }

    return true;
  }

  /**
   * Checks if the stored patient's gender matches the specified gender.
   *
   * @param {Gender} gender the expected gender
   *
   * @returns {boolean} true if the patient gender matches the expected one, false otherwise
   */
  private isPatientGender(gender: Gender): boolean {
    return this.sessionStorageService.patient.gender === gender;
  }

  /**
   * Checks if the patient is post-menopausal or has no menstrual period.
   *
   * @returns {boolean} true if the patient is post-menopausal or has no menstrual period, false otherwise
   */
  private isPostMenopausal(): boolean {
    return (
      this.sessionStorageService.getQuestionnaireAnswers(this.sessionStorageService.treatmentType)[
        this.config.lastPeriodQuestionId
      ] === LastMenstrualPeriod.PostMenopausal
    );
  }
}
