import { TreatmentConsultationRequest } from '@models/consultation-request/treatment-consultation-request';
import { PlaceOrderRequest } from '@models/place-order-request';

export class PlaceOrderConsultationRequest {
  order: PlaceOrderRequest;
  consult: TreatmentConsultationRequest;

  constructor(order: PlaceOrderRequest, consult: TreatmentConsultationRequest) {
    this.order = order;
    this.consult = consult;
  }
}
