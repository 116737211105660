import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestSessionGuard implements CanActivate {
  constructor(private router: Router, private sessionStorage: SessionStorageService) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @returns {boolean} true if the route can be activated, false otherwise
   */
  canActivate(): boolean {
    if (
      this.sessionStorage.transactionId &&
      this.sessionStorage.hash &&
      this.sessionStorage.consultationId &&
      this.sessionStorage.treatmentType
    ) {
      return true;
    }

    this.router.navigateByUrl(
      `/consultation/${this.sessionStorage.treatmentType || ConsultationTreatmentTypes.Std}/request`
    );

    return false;
  }
}
